<template>
  <div ref="top-anchor" class="border-21 products-accordion" style="/*overflow:hidden*/">
    <!-- Product list first | σκέτα products που δεν ανήκουν σε group -->
    <div v-if="category.products" :class="group_dim_grid(category.products.length)" class="dimensions-group">
      <div v-for="(product, index) in category.products" :key="index">
        
        <div
            class="variation-box vb-2-level border-t-1 border-21 sm:border-l-0 sm:border-r-0 px-4 cursor-pointer"
            :class="[secondLevelVariationClassObject(product, index), getCurrentItemClass(product)]"
            
            @click="toggleSelectedProduct(product)"
            
            style="background-position: top right;background-repeat: no-repeat;"

            :id="'single-product-' + product.item_code"
            
        >
          <p class="text-semibold manrope vb-2-level-itemname">{{ product.item_name }}</p>
          <p class="text-base text-medium manrope vb-2-level-itemcode">{{ product.item_code }}</p>
        </div> 

        
      </div>
    </div>
    

    <!-- Accordion | Product Groups -->
    <div class="variations-wrapper " v-for="(productGroup, index) in category.product_groups" :key="index">
      <!-- FIRST LEVEL VARIATIONS -->      
      <div
          :ref="'product-group-'+index"
          :id="'product-group-'+index"
          class="variation-box vb-1-level gp-sm-txt-34 gp-sm-lh-42 text-medium manrope text-darkgray px-4 sm:pt-12 cursor-pointer bg-fallback"
          :class="productGroupClass(productGroup.name)"
          style="position:relative"
          @click="toggleSelectedProductGroup(productGroup, index)"
      >
        {{ productGroup.name }}
        <span v-if="selectedProductGroup === productGroup.name"
          class="text-dark-gray prd-bck" style="text-decoration: underline;" 
        ><img src="../../assets/svg/arrow-left-wireframe.svg" style="display:inline" /> Back to dimensions</span>
      </div>

      <transition name="slide-down">
        <!-- SECOND LEVEL VARIATIONS -->
        <div v-if="isProductGroupSelected(productGroup.name)" :class="group_dim_grid(productGroup.products.length)" class="dimensions-group">
          <!-- Loop through product group's products -->
          <div v-for="(product, _index) in productGroup.products" :key="_index">
          <!--v-if="!isProductSelected(product.item_code)"-->
            <div
                class="variation-box vb-2-level border-t-1 border-21 sm:border-l-0 sm:border-r-0 px-4 cursor-pointer"
                :class="secondLevelVariationClassObject(product, _index)"
                @click="toggleSelectedProduct(product)"
                :id="'product-'+product.item_code"
            >
              <p class="vb-2-level-itemname text-semibold ">{{ product.item_name }}</p>
              <p class="text-base text-medium manrope vb-2-level-itemcode">{{ product.item_code }}</p>
            </div>

            <!-- Add to cart menu -->
            <transition name="fade-in">
            <add-to-cart-panel
                v-if="isProductSelected(product.item_code)"
                :product="product"
                :local-items="localItems"
            />
            </transition>
          </div>
        </div>
      </transition>
    </div>
    <div class="spacer" style="height:46px"></div>
  </div>
</template>

<script>
import AddToCartPanel from "@/components/products/AddToCartPanel";

export default {
  name: "ProductsAccordion",
  inject: ['updateLocalItemQuantity'],
  components: {
    AddToCartPanel
  },
  data() {
    return {
      selectedProductGroup: null,
      selectedProduct: null,
      myCurrentProduct: null
    }
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    localItems: {
      type: Array,
      required: true,
    },
    max_productname_len: {
      type: Number,
      required: true,
    },
    currentProduct: null,
    enableItemsInStockOnly: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    
  },
  watch: {
    currentProduct: {
      handler(newVal) {
        this.myCurrentProduct = newVal;
        this.goto('single-product-'+this.myCurrentProduct.item_code, 50);
        console.log("Parent=>currentProduct");
      },
      deep: true
    }
  },
  methods: {
    getCurrentItemClass(product) {
      if (product==this.myCurrentProduct && product.featured_image!="") {
        return 'blu-dot-bg';
      }
      else {
        return '';
      }
    },
    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
    getCoords(elem) { // crossbrowser version
        var box = elem.getBoundingClientRect();

        var top  = box.top;
        var left = box.left;

        return { top: Math.round(top), left: Math.round(left) };
    },
    goto(elName, offset = 0) {
      //console.log(elName);
      let element = document.getElementById(elName);
      //console.log(element);

      const getOffsetTop = element => {
        let offsetTop = 0;
        while(element) {
          offsetTop += element.offsetTop;
          element = element.offsetParent;
        }
        return offsetTop;
      }

      //let top = element.offsetTop;
      let top = getOffsetTop(element);

      //console.log(top); 
      window.scrollTo({ top: top - offset, behavior: 'smooth' });
    },
    goToAnchored(top) {
      window.scrollTo({ top: top, behavior: 'smooth' });
    },
    async toggleSelectedProductGroup(productGroup, index) {
      // reset selected product first
      this.selectedProduct = null;
      this.myCurrentProduct = null;



      if (this.selectedProductGroup === productGroup.name) {
        this.selectedProductGroup = null;
      } else {
        
        console.log(index);
        await this.delay(250);
        this.selectedProductGroup = productGroup.name;

        
      }
    },
    async toggleSelectedProduct(product) {
      if (product.inventory_total==0 && this.enableItemsInStockOnly) {
        //alert('...');
        return;
      }
      
      this.updateLocalItemQuantity(product, product.main_package_quantity_pcs);

      await this.delay(50);

      this.myCurrentProduct = product;
      this.$emit('sendCurrentProduct', product);
      
      let auth = this.$store.getters['auth/auth'];
      
      if (auth) {
        await this.delay(50);
        this.goto('add-to-cart-panel-container', 200);
        this.goto('selected-product-' + product.item_code, 200);

      }
      else {
        await this.delay(50);
        this.goto('please-login');
      }
    },
    isProductGroupSelected(productGroupId) {
      return this.selectedProductGroup === productGroupId;
    },
    productGroupClass(productGroupId) {      
      if (this.selectedProductGroup === productGroupId) {
        return "product-group-expanded";
      }
      else if (this.selectedProductGroup == null) {
        return "product-group-visible";
      }
      else {
        return "product-group-invisible";
      }
    },
    isProductSelected(productId) {
      return this.selectedProduct === productId;
    },
    bgIndexClass(index) {
      return 'bg-' + index % 4;
    },
    secondLevelVariationClassObject(variation, index) {
      const localItemIndex = this.localItems.findIndex(function(entry) {
        // console.log(entry.item, item);
        return entry.item.item_code === variation.item_code
      });
      //console.log(localItemIndex);
      
      return {
        /*'xl1441:hidden': this.isProductSelected(variation.item_code),*/
        'variation-hl': this.isProductSelected(variation.item_code),
        'bg-white border-l-0 border-r-0': this.isProductSelected(variation.item_code),
        'border-l-1 border-r-1': !this.isProductSelected(variation.item_code),
        [this.bgIndexClass(index)]: !this.isProductSelected(variation.item_code),
        'variation-active': localItemIndex>=0,
        'product-not-in-stock': variation.inventory_total<variation.main_package_quantity_pcs && this.enableItemsInStockOnly,
      }
    },
    group_dim_grid(variations_count) {
      if (variations_count==1) {
        return "group-dim-grid-1";
      }

      if (variations_count==2) {
        return "group-dim-grid-2";
      }

      if (this.max_productname_len<20) {
        return "group-dim-grid-4";
      }
      else if (this.max_productname_len<30) {
        return "group-dim-grid-3";
      }
      else {
        return "group-dim-grid-2";
      }

    }
  }
}
</script>

<style scoped>
/*.variation-box:last-child {*/
/*  border-bottom: 1px solid;*/
/*}*/






.prd-bck {position:absolute; top:10px; right:20px; font-size: 15px; }

.bg-0 {
  background-color: transparent;
}

.bg-1 {
  background-color: transparent;
  /*background-color: #F0EFE2;*/
}

.bg-2 {
  background-color: transparent;
  /*background-color: #EBEADF;*/
}

.bg-3 {
  background-color: transparent;
  /*background-color: #E5E4D9;*/
}


.vb-2-level {
  background-color:#ffffff;
  border:1px solid #212121;
  padding-top:20px;
  padding-bottom:20px;
}



.vb-2-level-itemname {
  font-size:21px;
  line-height:22px;  
}

.vb-2-level-itemcode {
  font-size:13px;
}


@media screen and (max-width: 640px) {
.prd-bck {    top: -35px;  }
}

@media screen and (max-width: 640px) {
  .variations-wrapper {padding:0px 20px;}
  .vb-1-level{padding-top: unset !important;}
}


@media screen and (max-width: 440px) {
  .vb-1-level{padding-top: unset !important; padding-bottom: unset !important;}
  .variation-box.vb-2-level{padding-top: 10px !important; padding-bottom: 10px !important;}
}

@media screen and (min-width: 640px) {
  

  .bg-fallback {
    background-color: transparent;
    /*background-color: #F0EFE2;*/
  }
}


.variation-box, .variation-box-single {
  border-color:#212121 !important;
  /*padding-right:25%;*/
}


.vb-1-level {
  font-size:26px; /**35 */
  padding-top:3px;
  padding-bottom:3px;
  border:1px solid #212121;
  border-radius: 10px;
  margin-top:-1px;
}

.vb-1-level:hover {
  background-color:#fff;
}

@media screen and (max-width:1600px) {
  .vb-1-level {
    font-size:30px;
    /*padding-top:30px;*/
  }
  .vb-2-level {
    padding-top:30px;
  }
}


@media screen and (max-width:1400px) {
  .vb-1-level {
    font-size:20px;
    /*padding-top:25px;*/
  }
  .vb-2-level {
    padding-top:25px;
  }

  .vb-2-level-itemname {
    font-size:20px;
  }


}


@media screen and (max-width:600px) {
  .products-accordion {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  .variations-wrapper {    padding: unset;}
  .spacer{height: unset !important;}
}

@media screen and (max-width:1023px) {
  .products-accordion {
    margin-left:20px;
    margin-right:20px;
  }
}


@media screen and (max-width:799px) {
  .products-accordion {
    margin-left:-2px;
    margin-right:-2px;
  }

  .variation-box, .variation-box-single {
    padding-left:23px;
    padding-right:23px;
  }

  .vb-2-level-itemname {
    font-size:24px;
  }

}


.variation-box, 
.variation-box-single,
.variation-box p,
.variation-box-single p {
  transition:all 0.5s;
}

.variation-box.vb-2-level {
  border-top:none;
  border-left:none;
  height:100%;
}

.variation-box.vb-2-level:hover {
  background-color: #efefe2;
}




.group-dim-grid-4 {
  display:grid;
  grid-template-columns: 25% 25% 25% 25%;
}
@media (max-width:800px) {
  .group-dim-grid-4 {
    grid-template-columns: 50% 50%;
  }
}
.group-dim-grid-3 {
  display:grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
}
@media (max-width:800px) {
  .group-dim-grid-3 {
    grid-template-columns: 100%;
  }
}
.group-dim-grid-2 {
  display:grid;
  grid-template-columns: 50% 50%;
}
@media (max-width:800px) {
  .group-dim-grid-2 {
    grid-template-columns: 100%;
  }
}
.group-dim-grid-1 {
  display:grid;
  grid-template-columns: 100%;
}

.product-group-visible {
  display:block;
}
.product-group-invisible {
  display:none;
}
.product-group-expanded {
  background-color: #d6b68c;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.product-group-expanded:hover {
  background-color: #d6b68c;
}


.variation-active.vb-2-level {
  background-color: #efefe2;
  translate:-4px -4px;
  box-shadow: 4px 3px #000;
  border:1px solid !important;
}

.dimensions-group {
  background-color: #fff;
  border:1px solid;
  position:relative;
  /*margin-right:-1px;*/
  
}
.dimensions-group:before {
  position:absolute;
  top:0px;
  left:0px;
  right:0px;
  bottom: -10px;
  content:'';
  background:#d6b68c;
  z-index: -1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border:1px solid #212121;
}

.group-dim-grid-1 > div:nth-child(1n) > div {
  border-right:none;
}
.group-dim-grid-2 > div:nth-child(2n) > div {
  border-right:none;
}
@media (max-width:800px) {
  .group-dim-grid-2 > div > div {
    border-right:none;
  }
}
.group-dim-grid-3 > div:nth-child(3n) > div {
  border-right:none;
}
@media (max-width:800px) {
  .group-dim-grid-3 > div > div {
    border-right:none;
  }
}
.group-dim-grid-4 > div:nth-child(4n) > div {
  border-right:none;
}
@media (max-width:800px) {
  .group-dim-grid-4 > div:nth-child(2n) > div {
    border-right:none;
  }
}

.blu-dot-bg {
  background-image: url(../../assets/svg/blue-dot.svg);
}

.product-not-in-stock {
  background-color: #ccc !important;
  color:#888;
  cursor:default !important;
}



</style>